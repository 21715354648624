import axios from 'axios';
// 这是获取指标的token
const ses_token = `T9wopMxJBltdUoWQdkbxRFKFeu7LcBnKzd6RBZovvCt8vRhYFyPPAcKrCbPuqaoIgOVF6yj5OJGJzLIpFAZYQGcMCDDMOegXQrCTvKj2GzrkQB192VE83w76ZSIwyNJB`
// 这是代理
const instance = axios.create({
	baseURL: '', //quota_server本地代理
	timeout: 60 * 1e3,
	headers: {ses_token},
});
instance.interceptors.request.use(function (config){
	if(!config.is_json){
		let ret = ''
		
		for (let it in config.data) {
			let datum = config.data[it];
			if(typeof  datum != 'string'){
				datum = JSON.stringify(datum);
			}
			ret += encodeURIComponent(it) + '=' + encodeURIComponent(datum) + '&'
		}
		  config.data = ret;
	}else{
		config.headers['Content-Type'] = 'application/json;charset=UTF-8';
		config.data = JSON.stringify(config.data);
	}
	console.log('config',config);
	return config
}, function (error) {
	return Promise.reject(error)
});
instance.interceptors.response.use(function (response) {
	return response.data
}, function (error) {
	console.log('$axiosForVe error',error);
	return Promise.reject(error)
});
// 这是接口地址
const url = '/quota_server/quota/call'

let timeoutDelay = {

}
export default instance;
