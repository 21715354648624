import quotaConf from "xb-ve-quota-conf/componets/quota-conf.vue";
import { getQuotaConf,} from "xb-ve-quota-conf/componets/getQuotaConf";
import { getQuotaApiTypeList, getQuotaPageApi } from "@/api/projects/getQuotaApiTypeList";

export default {
	components:{
		quotaConf,
	},
	data(){
		let { quota_conf_data,veParams } = getQuotaConf({
			ctx:this,
			root_el:'.genjiedian_PTakq8U',
			page_conf:{
				code:'haian-dsj-zhjg',
				name:'海安-大数据-综合监管',
				project:'海安',
				...getQuotaPageApi(),
			},
			quota_api_type_list:getQuotaApiTypeList(),
			quota_related_params:{
				'left_spxcgk':{
						name:'左-商铺巡查概况',
						val:{},
				},
				'left_spxj':{
						name:'左-商铺星级',
						val:{},
				},
				'right_spjgfx':{
						name:'右-商铺监管分析',
						val:{},
				},
			}
		})
		

		return {
			quota_conf_data,
			veParams,
		}
	},
	created() {
		this.quota_conf_data.watch();
	},
	methods: {
	
	}
}