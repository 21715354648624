//该文件由VE自动生成,VE导出合并时会被覆盖,请勿手动修改,需要补充的逻辑可以写在该目录下的js文件中,会被自动Mixin

const mixins = []
if (typeof require !== 'undefined') {
	const files = require.context('./', true, /.js$/);
	files.keys().forEach(item => {
		if (item !== './index.js') {
			mixins.push(files(item).default || files(item))
		}
	});
}

export default mixins

mixins.push({
	data: function() {
		return {
			veParams: {
				_global: {},
				开始: {
					key: 'startTime',
					value: '1698768000',
				},
				结束: {
					key: 'endTime',
					value: '1701412570',
				},
				填充时间: {
					key: 'fillTime',
					value: 'true',
				},
				getPcVDm7e: {},
			},
		}
	},
	watch: {
		'veParams.getPcVDm7e': {
			handler() {
				this.getPcVDm7e()
			},
		},

	},
	mounted() {

	},
	methods: {
		handleRate(res) {
			if (res.list && res.list[0]) {
				//总数
				let total = res.list[0].values.reduce((total, val) => total += (val * 1 || 0), 0);
				res.list.push({
					code: 'undefined',
					values: res.list[0].values.map(v => {
						return total ? (v / total * 100).toFixed(2) + '' : '0';
					})
				})
			}
		},
		async getPcVDm7e() {
			let params = {}
			let parmasArray = []
			parmasArray.map(name => {
				params[this.veParams[name].key] = this.veParams[name].value
			})
			params.quotaCodes = ''
		},

	}
})