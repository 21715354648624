export function handleSearchParams (ctx,new_date) {
	console.log('new_date',new_date)
	ctx.timeParams = {
		createTimeStart:new_date.start_time,
		createTimeEnd:new_date.end_time,
		startTime:new_date.start_time,
		endTime:new_date.end_time,
		
	};
	if(ctx.veParams.开始时间){
		ctx.veParams.开始时间.value = new_date.start_time;
	}
	if(ctx.veParams.结束时间){
		ctx.veParams.结束时间.value = new_date.end_time;
	}

	if(ctx.veParams.开始){
		ctx.veParams.开始.value = new_date.start_time;
	}
	if(ctx.veParams.结束){
		ctx.veParams.结束.value = new_date.end_time;
	}
	ctx.veParams._global = {
		createTimeStart:new_date.start_time,
		createTimeEnd:new_date.end_time,
		startTime:new_date.start_time,
		endTime:new_date.end_time,
	}
}