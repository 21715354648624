import instance from'./for-ve/axiosForVeSimple';
// import szcgApi from "./szcg";
// import szgyApi from "./szgy";
// import yllhApi from "./yllh";
// import hwApi from "./hw";
import zhjgApi from "./zhjg";
import apiZhjg from './zhjg/apiZhjg'

export const quotaPageSave = params => {
	return apiZhjg.http.post('/five_door_server/comprehensiveSupervision/save',{code:params.code,data:params.data,name:params.name},{is_json:true});
};
export const quotaPageGet = params => {
	return apiZhjg.http.post('/five_door_server/comprehensiveSupervision/config',{code:params.code});
 
};


export function getQuotaApiTypeList(){
	return [
		// {
		// 	code:'szcg_case_quota',
		// 	name:'数字城管-案件指标',
		// 	api:szcgApi.statisticCustomApiList,
		// 	apiCall:szcgApi.statisticCustomCallApi,
		// 	type:'event_quota',
		// },
		// {
		// 	code:'shizheng_form_quota',
		// 	name:'市政-表单指标',
		// 	api:szgyApi.quotaApiListStd,
		// 	apiCall:szgyApi.quotaApiCall,
		// 	type:'form_quota',
		// },
		// {
		// 	code:'shizheng_event_quota',
		// 	name:'市政-事件指标',
		// 	api:szgyApi.statisticCustomApiList,
		// 	apiCall:szgyApi.statisticCustomCallApi,
		// 	type:'event_quota',
		// },
		// {
		// 	code:'yuanlin_form_quota',
		// 	name:'园林-表单指标',
		// 	api:yllhApi.quotaApiListStd,
		// 	apiCall:yllhApi.quotaApiCall,
		// 	type:'form_quota',
		// },
		{
			code:'zhjg_form_quota',
			name:'综合监管-表单指标',
			api:zhjgApi.quotaApiListStd,
			apiCall:zhjgApi.quotaApiCall,
			type:'form_quota',
		},
		// {
		// 	code:'yuanlin_event_quota',
		// 	name:'园林-事件指标',
		// 	api:yllhApi.statisticCustomApiList,
		// 	apiCall:yllhApi.statisticCustomCallApi,
		// 	type:'event_quota',
		// },
		// {
		// 	code:'hw_custom_quota',
		// 	name:'环卫-手写指标',
		// 	api:hwApi.indicator_quota_apiList,
		// 	apiCall:hwApi.indicator_quota_call,
		// 	type:'form_quota',
		// },
	]
}

export function getQuotaPageApi(){
	return {
		saveApi:quotaPageSave,
		getApi:quotaPageGet,
	}
}