import moment from 'moment'
import { vBus } from "@/utils/vBus";
import { handleSearchParams } from "@/utils/handleSearchParams";
import hongheibangxiangqingPJRG from '@/vLibrary/projects/haianzonghejianguanyizhangtu-PFPw/pages/Down/hongheibangxiangqing-PJRG/index.vue'
export default {
    data() {
        return {
            leftShow: true,
            leftData: {
                month: moment(),
                time: 1,
            },
        }
    },
    computed: {
        PGToToS_active(){
            return this.PGToToS.datas.find(el => el.checked)?.value
        }
    },
    watch: {
        PGToToS_active: {
            handler(v){
                console.log('>>> checked: ', v)
            },
            immediate: true,
        },
        'leftData.time': {
            handler(v) {
                console.log('>>> time: ', v)
                this.quota_conf_data.quota_related_params.left_spxcgk.val = {
                    startTime: moment().subtract(v == 1 ? 7 : 30, 'day').unix(),
                    endTime: moment().unix()
                }
            },
            immediate: true,
        },
        'leftData.month': {
            handler(v) {
                console.log('>>> month: ', v.format?.('YYYY-MM'))
                this.quota_conf_data.quota_related_params.left_spxj.val = {
                    startTime: v.startOf('M').unix(),
                    endTime: v.endOf('M').unix()
                }
            },
            immediate: true,
        }
    },
    mounted() {
		handleSearchParams(this, {});
    },
    methods: {
        onLeftTogglt(value) {
            this.leftShow = value
        },
        onLeftTap(key) {
            console.log(key)
            switch(key) {
                case 'red' : 
                case 'black' : {
                    // this.$router.push({
                    //     name: 'hongheibangxiangqing-PJRG'
                    // })
                    
                    vBus.$emit('show-component-modal',({
                        component:hongheibangxiangqingPJRG,
                        props:{
                            date: this.leftData.month.format?.('YYYY-MM')
                        }
                    }));
                    break
                }
                default: {}
            }
        },
        onBack() {
            window.location.href = localStorage.getItem('app_biz_enter_url') || '/fivedoor/vue/pc/enter'
        },
        disabledEndDate(endValue) {
          const startValue = moment().endOf('M');
          if (!endValue || !startValue) {
            return false;
          }
          return startValue.valueOf() < endValue.valueOf();
        },
    },
}