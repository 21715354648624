import moment from "moment";
import { getEventList } from '@/api/projects/zhjg'
import EventListConponent from '@/views/event'
import { vBus } from "@/utils/vBus";
export default {
    data() {
        return {
            rightShow: true,
            rightData: {
                time: 1,
            },
            eventList: []
        }
    },
    computed: {
        PzwuZ3w_active(){
            return this.PzwuZ3w.datas.find(el => el.checked)?.value
        }
    },
    watch: {
        PzwuZ3w_active: {
            handler(v){
                console.log('>>> checked: ', v)
            },
            immediate: true,
        },
        'rightData.time': {
            handler(v) {
                console.log('>>> time: ', v)
                this.quota_conf_data.quota_related_params.right_spjgfx.val = {
                    startTime: moment().subtract(v == 1 ? 7 : 30, 'day').unix(),
                    endTime: moment().unix()
                }
            },
            immediate: true,
        }
    },
    created() {
        this.PNuOhfj.unit = ''
        this.getEventList()
    },
    methods: {
        getEventList() {
            getEventList({
                pageNum: 0,
                pageSize: 5,
                endTime: moment().unix(),
                startTime: moment().startOf('D').unix(),
            }).then(res => {
                if (res.ok) {
                    this.eventList = res.list
                    this.P30q0pA.content = `今日事件(${res.page.count})`
                }
            })
        },
        onRightTogglt(value) {
            this.rightShow = value
        },
        onEventList(item) {
            console.log('>>> event list')
            this.$router.push({
                name: 'eventlist',
                query: {
                    ...item,
                    // taskNum: 'CAAJ202403010012'
                }
            })
            // vBus.$emit('show-component-modal',({
            //     component:EventListConponent,
            //     props:{
            //         item,
            //     }
            // }));
        },
        onEventItem(item) {
            this.onEventList(item)
        },
        onRightTap(key) {
            console.log(key)
        },
    },
}