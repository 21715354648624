import moment from "moment/moment";
import { vBus } from "@/utils/vBus";

export default {
	components: {
	},
	data: function () {
		return {
		}
	},
	created () {
		this.PjUNJdL.datas[0].list.forEach(el => {
			el.value = 0
			el.value2 = ''
		})
		vBus.$on('onLegendChange', this.onLegendChange);
		// this.getData();
	},
	beforeDestroy() {
		this.onRemove();
		vBus.$off('onLegendChange', this.onLegendChange);
	},
	mounted () {
		this.onChange();
	},
	methods: {
		onChange() {
			const list = []
			const keys = {
				'正常商铺': 'shop',
				'问题商铺': 'shop',
				'监控': 'camera',
				'人员': 'user',
			}
			this.PjUNJdL.datas[0].list.forEach(el => {
				const types = []
				if (el.name == '问题商铺') {
					types.push(2)
				} else {
					types.push(1)
				}
				list.push({
					'name': keys[el.name],
					'project_name': 'zhjg',
					key: keys[el.name],
					is_show: el.checked,
					el,
					'filter': {
						"formCode": '',
						"ptype": 'point',
						types,
						type: {
							val: [...types]
						}
					}
				})
			})
			
			vBus.$emit('map-data-filter',{
				filter_list: list
			});
		},
		onRemove() {
			let list = []
			// this.PjUNJdL.datas[0].list.forEach(el => {
			// 	list.push({
			// 		'name': keys[el.name],
			// 		'project_name': 'zhjg',
			// 		is_show: false,
			// 		el: {},
			// 		'filter': {}
			// 	})
			// })
			
			
			
			vBus.$emit('map-data-filter',{
				filter_list:list
			});
		},
		onLegendChange({key, count}) {
			// const index = this.legendDatas.findIndex(item => item.key === key);
			// this.$set(this.legendDatas, index, {...this.legendDatas[index], count})
		}
	},
}
