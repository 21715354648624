
import clb2xngmajdqlnjrxm4i from '@/cLibrary/project/决策支持子系统/clb2xngmajdqlnjrxm4i/clb2xngmajdqlnjrxm4i.vue';
import cn0xf2khxegflriu42de from '@/cLibrary/project/海安市/cn0xf2khxegflriu42de/cn0xf2khxegflriu42de.vue';
import cbcq4ntids4olriuhhfe from '@/cLibrary/project/海安市/cbcq4ntids4olriuhhfe/cbcq4ntids4olriuhhfe.vue';
import cjw4z2ye15aplriv9uzl from '@/cLibrary/project/海安市/cjw4z2ye15aplriv9uzl/cjw4z2ye15aplriv9uzl.vue';
import cg0g4a2qfmfglrivgjfl from '@/cLibrary/project/海安市/cg0g4a2qfmfglrivgjfl/cg0g4a2qfmfglrivgjfl.vue';
import cm7z2xgkxmfalrivl1gp from '@/cLibrary/project/海安市/cm7z2xgkxmfalrivl1gp/cm7z2xgkxmfalrivl1gp.vue';
import c0qp048q8jeylriuo8k9 from '@/cLibrary/project/海安市/c0qp048q8jeylriuo8k9/c0qp048q8jeylriuo8k9.vue';
import camf7u0el0grlrfrcr0k from '@/cLibrary/project/海安市/camf7u0el0grlrfrcr0k/camf7u0el0grlrfrcr0k.vue';
import cyl4916cdtjblrfqry3e from '@/cLibrary/project/海安市/cyl4916cdtjblrfqry3e/cyl4916cdtjblrfqry3e.vue';
import c69d28iq1zqilrivodt5 from '@/cLibrary/project/海安市/c69d28iq1zqilrivodt5/c69d28iq1zqilrivodt5.vue';
import cxnd396w1zxlrivvgwx from '@/cLibrary/project/海安市/cxnd396w1zxlrivvgwx/cxnd396w1zxlrivvgwx.vue';
import c2cqnto6y72alrivzsgh from '@/cLibrary/project/海安市/c2cqnto6y72alrivzsgh/c2cqnto6y72alrivzsgh.vue';
import c8f1ljsgs91nlriw7idd from '@/cLibrary/project/海安市/c8f1ljsgs91nlriw7idd/c8f1ljsgs91nlriw7idd.vue';
import cpavr743kwyjlrfzqsxo from '@/cLibrary/project/海安市/cpavr743kwyjlrfzqsxo/cpavr743kwyjlrfzqsxo.vue';
import c2yaynnjmb0qlriz6mw1 from '@/cLibrary/project/海安市/c2yaynnjmb0qlriz6mw1/c2yaynnjmb0qlriz6mw1.vue';
import ca6ye24xxyflriyjnyp from '@/cLibrary/project/海安市/ca6ye24xxyflriyjnyp/ca6ye24xxyflriyjnyp.vue';
import csj2svetosylriyqlll from '@/cLibrary/project/海安市/csj2svetosylriyqlll/csj2svetosylriyqlll.vue';
import copgfflbgxqlrfszdfb from '@/cLibrary/project/海安市/copgfflbgxqlrfszdfb/copgfflbgxqlrfszdfb.vue';
import cpbdittyd6elriz0j88 from '@/cLibrary/project/海安市/cpbdittyd6elriz0j88/cpbdittyd6elriz0j88.vue';
import c1rjzoi2jn5lt40m74o from '@/cLibrary/project/海安市/c1rjzoi2jn5lt40m74o/c1rjzoi2jn5lt40m74o.vue';
import c8dgee1s3tf7lrftitd6 from '@/cLibrary/project/海安市/c8dgee1s3tf7lrftitd6/c8dgee1s3tf7lrftitd6.vue';
import cyel0kdai46lriyx08w from '@/cLibrary/project/海安市/cyel0kdai46lriyx08w/cyel0kdai46lriyx08w.vue';
import c44z2hhozcbklrizczsw from '@/cLibrary/project/海安市/c44z2hhozcbklrizczsw/c44z2hhozcbklrizczsw.vue';
import cp4yh5o9bx9lrizls4w from '@/cLibrary/project/海安市/cp4yh5o9bx9lrizls4w/cp4yh5o9bx9lrizls4w.vue';
import coxsjcm2urgslrizorfc from '@/cLibrary/project/海安市/coxsjcm2urgslrizorfc/coxsjcm2urgslrizorfc.vue';
import cxp2sgv3g298lrizsxg9 from '@/cLibrary/project/海安市/cxp2sgv3g298lrizsxg9/cxp2sgv3g298lrizsxg9.vue';
import c8dpakk475mplrfrsn5h from '@/cLibrary/project/海安市/c8dpakk475mplrfrsn5h/c8dpakk475mplrfrsn5h.vue';
import cg0eswmpzwstlrftqq9f from '@/cLibrary/project/海安市/cg0eswmpzwstlrftqq9f/cg0eswmpzwstlrftqq9f.vue';
import csz3zky65nklriv0b5t from '@/cLibrary/project/海安市/csz3zky65nklriv0b5t/csz3zky65nklriv0b5t.vue';
import ck4paebihwr9lrfyx689 from '@/cLibrary/project/海安市/ck4paebihwr9lrfyx689/ck4paebihwr9lrfyx689.vue';
import c7wgmyby1fcelrfyj9eo from '@/cLibrary/project/海安市/c7wgmyby1fcelrfyj9eo/c7wgmyby1fcelrfyj9eo.vue';
import c882l6rd9k8lriv4pq9 from '@/cLibrary/project/海安市/c882l6rd9k8lriv4pq9/c882l6rd9k8lriv4pq9.vue';
import crcudju24u1rlrg05pl1 from '@/cLibrary/project/海安市/crcudju24u1rlrg05pl1/crcudju24u1rlrg05pl1.vue';
import chk2xlrjtudelrizzkm7 from '@/cLibrary/project/海安市/chk2xlrjtudelrizzkm7/chk2xlrjtudelrizzkm7.vue';
import cuwf60epvk7lq4k8asx from '@/cLibrary/project/曲靖马龙/cuwf60epvk7lq4k8asx/cuwf60epvk7lq4k8asx.vue';
import cev4apkqwyklq4kmsl7 from '@/cLibrary/project/曲靖马龙/cev4apkqwyklq4kmsl7/cev4apkqwyklq4kmsl7.vue';
import coxqv0838xwqlq4kowpc from '@/cLibrary/project/曲靖马龙/coxqv0838xwqlq4kowpc/coxqv0838xwqlq4kowpc.vue';
import ci9xwhqkcroflq4kqyyw from '@/cLibrary/project/曲靖马龙/ci9xwhqkcroflq4kqyyw/ci9xwhqkcroflq4kqyyw.vue';
import cfdi2w60vixelnvjijnt from '@/cLibrary/project/风险分布管理/cfdi2w60vixelnvjijnt/cfdi2w60vixelnvjijnt.vue';
import mixins from './service';

export default {
	name: 'fangwusheshi-Ppkh',
	mixins,
	data: function() {
		return {
			images: {
				logo_PozHtOs: `${window.baseUrl}oss-images/20240118/1705572080P3oK.png`,
				shijian_Prkp4j7: `${window.baseUrl}oss-images/20240118/1705572158Pn7h.png`,
				youce_Phlt7By: `${window.baseUrl}oss-images/20240118/1705572236PqVY.png`,
				top_PQ8zH7v: `${window.baseUrl}oss-images/20240118/1705571853P6Aq.png`,
				xuanzeqi_Pe0svrM: `${window.baseUrl}oss-images/20240118/1705573295P0rQ.png`,
				zhibiaozu_Pus4Wtl: `${window.baseUrl}oss-images/20240118/1705573368Pen1.png`,
				xuanzeqi_PrMnBco: `${window.baseUrl}oss-images/20240118/1705574482PjJc.png`,
				zhumianbankuang_Pa81ody: `${window.baseUrl}oss-images/20240119/1705647373Pbiw.png`,
				zhumianbankuang_P13OlnF: `${window.baseUrl}oss-images/20240119/1705647383P72L.png`,
				xuanzeqi_PWiMjM8: `${window.baseUrl}oss-images/20240119/1705636389PNiF.png`,
				title_PB7IfKa: `${window.baseUrl}oss-images/20240119/1705644731Pb6R.png`,
				pic_PAxqj1u: `${window.baseUrl}oss-images/20240119/1705644973P6fd.png`,
				zhuangtaibiaoqian_PYCTbSN: `${window.baseUrl}oss-images/20240119/1705645252PTMr.png`,
				shebeigaojing_PPpTaPK: `${window.baseUrl}oss-images/20240119/1705647448PJmd.png`,
				genjiedian_PTakq8U: `${window.baseUrl}oss-images/20240118/1705570477PZYK.jpg`,
			},
			PFl8eJ1: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PeaFS0n: {
				"content": "综合监管一张图"
			},
			PiLGZdW: {
				"title": "商铺概况"
			},
			PbIFTjH: {
				"title": "基础统计"
			},
			PLsjXad: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "商户数量",
				"titleWrap": 0,
				"indicator": "20",
				"unit": "",
				"deputyUnit": ""
			},
			PVfteQw: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "路段数量",
				"titleWrap": 0,
				"indicator": "20",
				"unit": "",
				"deputyUnit": ""
			},
			PqQNNqz: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "监控数量",
				"titleWrap": 0,
				"indicator": "20",
				"unit": "",
				"deputyUnit": ""
			},
			PQVDyLe: {
				"title": "按路段统计"
			},
			PEKxh7l: {
				"datas": [{
					"name": "路段名称一",
					"title": "商铺数",
					"value": "500",
					"rate": ""
				}, {
					"name": "路段名称二",
					"title": "商铺数",
					"value": "300",
					"rate": ""
				}, {
					"name": "路段名称三",
					"title": "商铺数",
					"value": "100",
					"rate": ""
				}],
				"unit": "个",
				"rateUnit": "%"
			},
			PwkEq6o: {
				"title": "商铺类型统计"
			},
			PyZ8yE9: {
				"datas": [{
					"name": "类型一",
					"value": "30",
					"rate": "30"
				}, {
					"name": "类型二",
					"value": "25",
					"rate": "25"
				}, {
					"name": "类型三",
					"value": "20",
					"rate": "20"
				}, {
					"name": "类型四",
					"value": "12",
					"rate": "12"
				}, {
					"name": "类型五",
					"value": "8",
					"rate": "8"
				}, {
					"name": "其他",
					"value": "5",
					"rate": "5"
				}],
				"unit": ""
			},
			Pg2Gt6h: {
				"title": "商铺星级"
			},
			PnyWsTc: {
				"title": "",
				"indicator": "5679",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": false
			},
			PETPDzm: {
				"title": "",
				"indicator": "5679",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": false
			},
			PAytiFc: {
				"title": "",
				"indicator": "5679",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": false
			},
			PI8bb08: {
				"title": "",
				"indicator": "5679",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": false
			},
			P4WjJTr: {
				"datas": [{
					"name": "四星商铺",
					"value": "102",
					"rate": "8.2"
				}, {
					"name": "三星商铺",
					"value": "102",
					"rate": "8.3"
				}, {
					"name": "二星商铺",
					"value": "218",
					"rate": "17.5"
				}, {
					"name": "一星商铺",
					"value": "820",
					"rate": "66"
				}],
				"titles": {
					"name": "总量",
					"value": "1242"
				},
				"unit": "个"
			},
			Pz4xTv8: {
				"title": "商铺红黑榜"
			},
			Pss1t4u: {
				"title": "黑榜商铺",
				"indicator": "455",
				"unit": "个",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			P3GrpKt: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PiR7imx: {
				"title": "红榜商铺",
				"indicator": "455",
				"unit": "个",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			PXBXHbr: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PShfpSw: {
				"title": "巡查概况"
			},
			PHIR1at: {
				"xAxis": {},
				"y1Axis": {},
				"y2Axis": {},
				"xname": "天",
				"xdata": ["09/27", "09/28", "09/29", "09/30", "10/01", "10/02", "10/03"],
				"y1check": true,
				"y1name": "件",
				"y1max": 0,
				"y1min": 0,
				"y1data": [{
					"name": "巡查数",
					"data": ["90", "110", "170", "140", "200", "120", "160"]
				}],
				"y2check": false,
				"y2name": "次",
				"y2max": 0,
				"y2min": 0,
				"y2data": [{
					"name": "出勤人次",
					"data": ["310", "300", "450", "550", "560", "660", "720"]
				}]
			},
			PLFvqF5: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "巡查商铺数量",
				"titleWrap": 0,
				"indicator": "207",
				"unit": "",
				"deputyUnit": ""
			},
			PO7wwHW: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "巡查商铺数量",
				"titleWrap": 0,
				"indicator": "207",
				"unit": "",
				"deputyUnit": ""
			},
			PlnFese: {
				"title": "队员处置排行"
			},
			PGToToS: {
				"datas": [{
					"name": "巡查",
					"value": 1,
					"checked": true
				}, {
					"name": "问题处置",
					"value": "2",
					"checked": false
				}]
			},
			PP2bf74: {
				"datas": [{
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "500",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "300",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "100",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "50",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "50",
					"rate": ""
				}],
				"unit": "个",
				"rateUnit": "%"
			},
			PSxSbVj: {
				"datas": [{
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "500",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "300",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "100",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "50",
					"rate": ""
				}, {
					"name": "队员名称",
					"title": "巡查商铺数",
					"value": "50",
					"rate": ""
				}],
				"unit": "个",
				"rateUnit": "%"
			},
			ParhapE: {
				"title": "商铺违规概况"
			},
			P7Pu9nR: {
				"title": "问题数量",
				"indicator": "20",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			PGzRkoe: {
				"title": "已处置",
				"indicator": "20",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			PMk1dXH: {
				"title": "待处置",
				"indicator": "20",
				"unit": "",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			PpxxIVy: {
				"title": "问题处置率",
				"indicator": "98.6",
				"unit": "%",
				"deputyTitle": "",
				"deputyIndicator": "",
				"deputyUnit": "",
				"up": true
			},
			PN72fSS: {
				"title": "问题处置分析"
			},
			Pt7S6Ou: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "商家整改",
				"titleWrap": 0,
				"indicator": "20",
				"unit": "",
				"deputyUnit": ""
			},
			PgdDMCK: {
				"deputyTitle": "",
				"deputyIndicator": "",
				"up": false,
				"title": "队员处置",
				"titleWrap": 0,
				"indicator": "20",
				"unit": "",
				"deputyUnit": ""
			},
			PNuOhfj: {
				"datas": [{
					"name": "队员处置",
					"value": "60",
					"rate": ""
				}, {
					"name": "商家整改",
					"value": "40",
					"rate": ""
				}],
				"titles": {
					"name": "总量",
					"value": "420"
				},
				"unit": "%"
			},
			P66sKpU: {
				"title": "问题趋势分析"
			},
			P5UOwGj: {
				"xAxis": {},
				"y1Axis": {},
				"y2Axis": {},
				"xname": "日",
				"xdata": ["10.7", "10.8", "10.9", "10.10", "10.11", "10.12", "10.13"],
				"y1check": true,
				"y1name": "件",
				"y1max": 0,
				"y1min": "0",
				"y1data": [{
					"name": "问题数量",
					"data": ["100", "60", "80", "70", "100", "60", "80", "70", "80", "90", "30"]
				}],
				"y2check": true,
				"y2name": "%",
				"y2max": 100,
				"y2min": 0,
				"y2data": [{
					"name": "处置率",
					"data": ["98", "96", "89", "85", "96", "99", "77", "96", "89", "85", "96", "99"]
				}]
			},
			PCp0jnw: {
				"title": "高发时段分析"
			},
			P8J2Awf: {
				"xAxis": {},
				"y1Axis": {},
				"y2Axis": {},
				"xname": "天",
				"xdata": ["6:00", "8:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00", ""],
				"y1check": true,
				"y1name": "件",
				"y1max": 0,
				"y1min": 0,
				"y1data": [{
					"name": "巡查商铺数",
					"data": ["90", "110", "170", "140", "200", "120", "160", "104", "89", "130"]
				}],
				"y2check": false,
				"y2name": "次",
				"y2max": 0,
				"y2min": 0,
				"y2data": [{
					"name": "出勤人次",
					"data": ["310", "300", "450", "550", "560", "660", "720"]
				}]
			},
			PTDlIIX: {
				"title": "问题来源分析"
			},
			PAlvjYv: {
				"datas": [{
					"name": "来源一",
					"value": "30",
					"rate": "30"
				}, {
					"name": "来源二",
					"value": "25",
					"rate": "25"
				}, {
					"name": "来源三",
					"value": "20",
					"rate": "20"
				}, {
					"name": "来源四",
					"value": "12",
					"rate": "12"
				}, {
					"name": "来源五",
					"value": "8",
					"rate": "8"
				}, {
					"name": "其他",
					"value": "5",
					"rate": "5"
				}],
				"unit": ""
			},
			P5ivT9h: {
				"datas": [{
					"name": "商家名称",
					"title": "违规数",
					"value": "500",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "300",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "100",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "50",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "50",
					"rate": ""
				}],
				"unit": "个",
				"rateUnit": "%"
			},
			PLvRMEV: {
				"datas": [{
					"name": "商家名称",
					"title": "违规数",
					"value": "500",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "300",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "100",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "50",
					"rate": ""
				}, {
					"name": "商家名称",
					"title": "违规数",
					"value": "50",
					"rate": ""
				}],
				"unit": "个",
				"rateUnit": "%"
			},
			PlLMQID: {
				"title": "违规路段/商家排行"
			},
			PzwuZ3w: {
				"datas": [{
					"name": "路段",
					"value": 1,
					"checked": true
				}, {
					"name": "商家",
					"value": "2",
					"checked": false
				}]
			},
			PZmP4Ja: {
				"title": "商铺监管分析"
			},
			P30q0pA: {
				"content": "今日事件(5)"
			},
			Px7Okr2: {
				"content": "商铺名称XXX..."
			},
			PcVDm7e: {
				"content": "XXXXXXXXXXXXXXXXX..."
			},
			PyHsUCH: {
				"content": "2022-01-11  10:06:02"
			},
			PUosgjG: {
				"content": "待处置"
			},
			Pz0FB2l: {
				"title": "查看更多",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PjUNJdL: {
				"datas": [{
					"title": {
						"name": "资源上图",
						"checked": true,
						"open": true
					},
					"list": [{
						"name": "正常商铺",
						"value": "54",
						"value2": "24",
						"checked": true
					}, {
						"name": "问题商铺",
						"value": "54",
						"value2": "20",
						"checked": true
					}, {
						"name": "监控",
						"value": "24",
						"value2": "",
						"checked": true
					}, {
						"name": "人员",
						"value": "54",
						"value2": "24",
						"checked": true
					}]
				}]
			},
			Phd6L15: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PRzHc0z: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			Pv6qdLc: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PidxOYb: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
		}
	},
	methods: {
		getBackground(name) {
			return {
				backgroundImage: `url(${this.images[name]})`
			}
		},

	},
	components: {
		clb2xngmajdqlnjrxm4i,
		cn0xf2khxegflriu42de,
		cbcq4ntids4olriuhhfe,
		cjw4z2ye15aplriv9uzl,
		cg0g4a2qfmfglrivgjfl,
		cm7z2xgkxmfalrivl1gp,
		c0qp048q8jeylriuo8k9,
		camf7u0el0grlrfrcr0k,
		cyl4916cdtjblrfqry3e,
		c69d28iq1zqilrivodt5,
		cxnd396w1zxlrivvgwx,
		c2cqnto6y72alrivzsgh,
		c8f1ljsgs91nlriw7idd,
		cpavr743kwyjlrfzqsxo,
		c2yaynnjmb0qlriz6mw1,
		ca6ye24xxyflriyjnyp,
		csj2svetosylriyqlll,
		copgfflbgxqlrfszdfb,
		cpbdittyd6elriz0j88,
		c1rjzoi2jn5lt40m74o,
		c8dgee1s3tf7lrftitd6,
		cyel0kdai46lriyx08w,
		c44z2hhozcbklrizczsw,
		cp4yh5o9bx9lrizls4w,
		coxsjcm2urgslrizorfc,
		cxp2sgv3g298lrizsxg9,
		c8dpakk475mplrfrsn5h,
		cg0eswmpzwstlrftqq9f,
		csz3zky65nklriv0b5t,
		ck4paebihwr9lrfyx689,
		c7wgmyby1fcelrfyj9eo,
		c882l6rd9k8lriv4pq9,
		crcudju24u1rlrg05pl1,
		chk2xlrjtudelrizzkm7,
		cuwf60epvk7lq4k8asx,
		cev4apkqwyklq4kmsl7,
		coxqv0838xwqlq4kowpc,
		ci9xwhqkcroflq4kqyyw,
		cfdi2w60vixelnvjijnt,
	},
}
